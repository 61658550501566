import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardBody, Row, Col } from 'reactstrap';
// import StarRatings from 'react-star-ratings';
import { Translate, withLocalize } from 'react-localize-redux';

import getPath from '../assets/routes/paths';

import demoCourse from '../assets/img/demo-course.jpg';

const CourseCard = ({ course, language, translate, highlight }) => {
  // console.log('course', course, course.img);
  return (
    <Link to={`${getPath(language, 'GolfCourses')}${course.path}`}>
      <Card className="card-image" style={highlight ? { border: '4pt solid #5E8A7C' } : {}}>
        {course.image && (
          <img
            alt={course.name}
            className="img-responsive"
            src={course.img}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = demoCourse;
            }}
            style={{ minHeight: '200px', maxHeight: '200px', objectFit: 'cover' }}
          />
        )}
        <CardBody>
          <div className="card-description">
            <div style={{ minHeight: '35px' }}>
              <h5 className="title mb-0" style={{ fontSize: '1.1em', minHeight: '23px', fontWeight: 'bold' }}>
                {course.golfclub} {course.distance ? <span className="small">({course.distance} km)</span> : ''}
              </h5>
            </div>
            {course.other && <p className="alert alert-warning">{course.other}</p>}
            {course.description && <p style={{ minHeight: '130px' }}>{course?.description.substring(0, 100)}</p>}
            <Row>
              <Col xs="8">
                <h6 className="title" style={{ fontSize: '12px' }}>
                  {course.district}
                </h6>
              </Col>
              <Col>
                {course.q2 > 0 && (
                  <>
                    <i className="fa fa-star" /> {course.q2}
                  </>
                )}
              </Col>
            </Row>
            {course.plusTimes && (
              <div
                className="scroll"
                style={{
                  minHeight: '130px',
                  maxHeight: '130px',
                  overflowY: 'scroll'
                }}
              >
                <h6 className="title">
                  <Translate id="plus.plus-times-available" />
                </h6>
                <>
                  {course.plusTimes.map((p) => (
                    <p key={course.golfclubId + p.startDate + p.times[0]} style={{ marginTop: '10px' }}>
                      <i className="fa fa-calendar" style={{ fontSize: '10px', marginRight: '5px' }} />
                      {p.startDate} {p.startDate !== p.endDate ? ` - ${p.endDate}` : ``}
                      <br />
                      {p.times.map((t) => (
                        <span key={t}>
                          {t}
                          <br />
                        </span>
                      ))}
                    </p>
                  ))}
                </>
              </div>
            )}
            {!course.plusTimes && (
              <Row>
                <Col className="pt-2">
                  {course.days === 1 ? translate('course.restr-all') : translate('course.restr-weekdays')}
                  {course.week_rest && `. ${translate('course.week-exc')} ${course.week_rest}`}
                </Col>
              </Row>
            )}
            {language === 'dk' && course.flex === -1 && (
              <Row className="mt-3">
                <Col>
                  <p>
                    <Translate id="default.flex" />
                  </p>
                </Col>
              </Row>
            )}
          </div>
        </CardBody>
      </Card>
    </Link>
  );
};

CourseCard.propTypes = {
  // course: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
};

export default withLocalize(CourseCard);
