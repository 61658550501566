import React, { useContext, useEffect, useState } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { Container, Row, Col } from 'reactstrap';
import Helmet from '../components/Helmet';
import Title from '../components/Title';
import Button from '../components/Button';
import { Store } from 'Store';
import MapList from 'components/Map/MapList';
import CourseCard from 'components/CourseCard';

const coursesToDisplayOnPage = [1958, 1550, 1399, 1637, 5221, 1697, 7215, 5151, 5271];

function Bunkered({ translate }) {
  document.documentElement.classList.remove('nav-open');

  const { state } = useContext(Store);
  const { data: courses } = state.courses;

  const [coursesToDisplay, setCoursesToDisplay] = useState(null);

  useEffect(() => {
    document.body.classList.add('how-it-works');
    window.scrollTo(0, 0);
    return function cleanup() {
      document.body.classList.remove('how-it-works');
    };
  }, []);

  useEffect(() => {
    if (courses) {
      setCoursesToDisplay(courses.filter((c) => coursesToDisplayOnPage.includes(c.id)));
    }
  }, [courses]);

  return (
    <>
      <Helmet title="how-it-works.title" />
      <Title title={translate('how-it-works.title')} />
      <div className="section mt-3 text-center">
        <Container className="flags">
          <img src="/react-assets/img/flag-SE.png" alt="SE" title="Sweden" />
          <img src="/react-assets/img/flag-DK.png" alt="DK" title="Denmark" />
          <img src="/react-assets/img/flag-NO.png" alt="NO" title="Norway" />
          <img src="/react-assets/img/flag-FI.png" alt="FI" title="Finland" />
          <img src="/react-assets/img/flag-DE.png" alt="DE" title="Germany" />
          <img src="/react-assets/img/flag-FR.png" alt="FR" title="France" />
          <img src="/react-assets/img/flag-ES.png" alt="ES" title="Spain" />
          <img src="/react-assets/img/flag-IT.png" alt="IT" title="Italy" />
          <img src="/react-assets/img/flag-PT.png" alt="PT" title="Portugal" />
          <img src="/react-assets/img/flag-GR.png" alt="GR" title="Greece" />
          <img src="/react-assets/img/flag-CH.png" alt="CH" title="Switzerland" />
          <img src="/react-assets/img/flag-BE.png" alt="BE" title="Belgium" />
          <img src="/react-assets/img/flag-NL.png" alt="NL" title="Netherlands" />
          <img src="/react-assets/img/flag-GB.png" alt="GB" title="Great Britain" />
          <img src="/react-assets/img/flag-IR.png" alt="IR" title="Ireland" />
          <img src="/react-assets/img/flag-EE.png" alt="EE" title="Estonia" />
          <img src="/react-assets/img/flag-LV.png" alt="LV" title="Latvia" />
          <img src="/react-assets/img/flag-PL.png" alt="PL" title="Polen" />
          <img src="/react-assets/img/flag-CZ.png" alt="CZ" title="Czech Republic" />
          <img src="/react-assets/img/flag-SK.png" alt="SK" title="Slovakia" />
          <img src="/react-assets/img/flag-HU.png" alt="HU" title="Hungary" />
        </Container>
      </div>
      <div className="section section-gray mt-1">
        <Container className="small-width">
          <Row>
            <Col className="ml-auto mr-auto">
              <h3 className="title">
                <Translate id="how-it-works.p1-title" />
              </h3>
              <p style={{ fontSize: '1.4em', lineHeight: '1.65em' }}>
                <Translate id="how-it-works.p1-text" />
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section mt-3">
        <Container className="small-width">
          <Row>
            <Col className="ml-auto mr-auto">
              <h3 className="title">
                <Translate id="how-it-works.p2-title" />
              </h3>
              <Row>
                <Col xs="2" sm="1" className="mb-5">
                  <div className="circle-with-text big">1</div>
                </Col>
                <Col>
                  <p>
                    <Translate id="how-it-works.p2-1-text" />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="2" sm="1" className="offset-sm-3 mb-5">
                  <div className="circle-with-text big">2</div>
                </Col>
                <Col>
                  <p>
                    <Translate
                      id="how-it-works.p2-2-text"
                      data={{
                        appStore: (
                          <a
                            href="https://itunes.apple.com/se/app/golfh%C3%A4ftet/id957686045?mt=8"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            App Store
                          </a>
                        ),
                        googlePlay: (
                          <a
                            href="https://play.google.com/store/apps/details?id=com.golfhaftet.ghApp"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Google Play
                          </a>
                        )
                      }}
                    />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="2" sm="1" className="mb-5">
                  <div className="circle-with-text big">3</div>
                </Col>
                <Col>
                  <p>
                    <Translate id="how-it-works.p2-3-text" />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs="2" sm="1" className="offset-sm-3 mb-5">
                  <div className="circle-with-text big">4</div>
                </Col>
                <Col>
                  <p>
                    {/* <Translate id="how-it-works.p2-4-text" data={{ link: <Link to={getPath(language, 'Faq')} /> }} /> */}
                    <Translate id="how-it-works.p2-4-text" />
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section">
        <Container>
          <h3 className="mb-2 mt-0">Golf clubs mentioned in the Bunkered article</h3>
          <Row>
            <Col>{coursesToDisplay && <MapList courses={coursesToDisplay} component={CourseCard} showAllCourses showList />}</Col>
          </Row>
        </Container>
      </div>

      <div style={{ position: 'relative' }}>
        <img src="/react-assets/img/pink-socks.jpg" alt="Buy Golfamore" style={{ width: '100%', minHeight: '150px' }} />
        <div
          style={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center'
          }}
        >
          <h2 className="medium" style={{ color: '#fff' }}>
            <Translate id="default.play-golf" />
          </h2>
          <Button text={translate('default.buy-golfhaftet')} link="Webshop" />
        </div>
      </div>
    </>
  );
}

export default withLocalize(Bunkered);
