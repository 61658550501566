import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Translate, withLocalize } from 'react-localize-redux';

import getPath from '../assets/routes/paths';

const CourseCardCompact = ({ course, language, translate, highlight }) => {
  return (
    <Link to={`${getPath(language, 'GolfCourses')}${course.path}`}>
      <div style={highlight ? { border: '4pt solid #5E8A7C' } : { borderBottom: '1pt solid #ccc' }}>
        <Row className="pt-3 px-1">
          <Col>
            <h5 className="title mb-0 mt-0" style={{ fontSize: '1.1em', minHeight: '23px', fontWeight: 'bold' }}>
              {course.golfclub} {course.distance ? <span className="small">({course.distance} km)</span> : ''}
            </h5>
            <h6 className="title mt-0" style={{ fontSize: '12px' }}>
              {course.district}
            </h6>
          </Col>

          <Col className="text-right">
            {course.q2 && (
              <>
                <i className="fa fa-star" /> {course.q2}
                <br />
              </>
            )}
            <p>
              {course.days === 1 ? translate('course.restr-all') : translate('course.restr-weekdays')}
              {course.week_rest && `. ${translate('course.week-exc')} ${course.week_rest}`}
            </p>
          </Col>
        </Row>
        {course.other && <Row className="pt-3 px-1">{course.other && <p className="alert alert-warning">{course.other}</p>}</Row>}
        {language === 'dk' && course.flex === -1 && (
          <Row>
            <Col>
              <p>
                <Translate id="default.flex" />
              </p>
            </Col>
          </Row>
        )}
      </div>
    </Link>
  );
};

CourseCardCompact.propTypes = {
  // course: PropTypes.object.isRequired,
  language: PropTypes.string.isRequired
};

export default withLocalize(CourseCardCompact);
