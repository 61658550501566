import React from 'react';
import StarRatings from 'react-star-ratings';
import styled from 'styled-components';
import { Translate } from 'react-localize-redux';
import { Link } from 'react-router-dom';
import getPath from '../../assets/routes/paths';
import getLanguage from '../../services/helpers';

const IWindow = styled.div`
  position: relative;
  top: 0;
  left: -130px;
  width: 260px;
  background-color: white;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
  padding: 0;
  font-size: 14;
  z-index: 100;
`;

const InfoWindow = (props) => {
  const language = getLanguage();
  const { place } = props;

  return (
    <>
      <IWindow onClick={(e) => e.stopPropagation()}>
        {place.image && <img src={`${place.image.path + place.image.small}`} alt={place.name} style={{ width: '100%' }} />}
        <div style={{ padding: 20 }}>
          <h4 className="title mt-0 mb-1" style={{ minHeight: 'auto' }}>
            {place.golfclub}
          </h4>
          <h5 className="title bold mt-0 mb-1">{place.district}</h5>

          {place.q2 && (
            <StarRatings
              rating={place.q2}
              starRatedColor="#5E8A7C"
              starDimension="15px"
              starSpacing="3px"
              numberOfStars={5}
              name="rating"
            />
          )}

          {place.other && <p className="alert alert-warning">{place.other}</p>}

          {place.plusTimes && (
            <>
              <p>
                <b>
                  <Translate id="plus.plus-times-available" />
                </b>
              </p>
              <div
                className="scroll"
                style={{
                  minHeight: '130px',
                  maxHeight: '130px',
                  overflowY: 'scroll'
                }}
              >
                {place.plusTimes.map((p) => (
                  <p
                    key={p.startDate}
                    style={{
                      marginTop: '10px'
                    }}
                  >
                    <i className="fa fa-calendar" style={{ fontSize: '10px', marginRight: '5px' }} />
                    {p.startDate} {p.startDate !== p.endDate ? ` - ${p.endDate}` : ``}
                    <br />
                    {p.times.map((t) => (
                      <span key={t}>
                        {t}
                        <br />
                      </span>
                    ))}
                  </p>
                ))}
              </div>
            </>
          )}
          <Link to={`${getPath(language, 'GolfCourses')}${place.path}`}>
            <div className="text-dark-success pull-right text-bold">
              <Translate id="default.show-more" /> &raquo;
            </div>
          </Link>
        </div>
      </IWindow>
    </>
  );
};

export default InfoWindow;

/*
<div style={{ fontSize: 14 }}>
  <ul className="rating" data-rating={Math.round(place.q4 * 2) / 2}>
    <li className="rating-item" />
    <li className="rating-item" />
    <li className="rating-item" />
    <li className="rating-item" />
    <li className="rating-item" />
  </ul>
</div>
*/
