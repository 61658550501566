import React, { memo } from 'react';
import { Helmet as RHelmet } from 'react-helmet';
import { withLocalize } from 'react-localize-redux';
import getLanguage from '../services/helpers';

function Helmet({ title, description, translate, translatedTitle = null, noIndex = false }) {
  const language = getLanguage();
  // console.log('canonical', window.location.href);

  title = title?.replace('<br/>', '').replace('<br />', '');
  const strTitle = translatedTitle || (title && `${translate(title, null, { renderInnerHtml: false })}`);
  const strDescription = description && `${translate(description, null, { renderInnerHtml: false })}`;
  // console.log('title', strTitle);

  return (
    <RHelmet>
      <html lang={language} />
      <title>{strTitle}</title>
      {strDescription && <meta name="description" content={strDescription} />}
      {noIndex && <meta name="robots" content="noindex, nofollow" />}
      <link rel="canonical" href={window.location.href} />
    </RHelmet>
  );
}

const propsAreEqual = (prev, next) => {
  return prev.title === next.title && prev.translatedTitle === next.translatedTitle;
};

export default memo(withLocalize(Helmet), propsAreEqual);
