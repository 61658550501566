import React, { lazy } from 'react';
import Marker from './Marker';

const GoogleMapReact = lazy(() => import('google-map-react'));

const GMap = ({
  courses,
  zoom,
  center,
  onChildClickCallback,
  onMapClickCallback,
  yesIWantToUseGoogleMapApiInternals,
  onGoogleApiLoaded,
  onChange,
  height
}) => {
  // console.log('GMap render');
  return (
    <div style={{ width: '100%', height: height || '600px' }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: 'AIzaSyAbke4Wdip5ELXH8kTntmH1tyLlR6OWLFY',
          region: 'MA'
        }}
        // defaultZoom={defaultZoom}
        zoom={zoom}
        // defaultCenter={defaultCenter}
        center={center}
        onChildClick={onChildClickCallback}
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={onChange}
        yesIWantToUseGoogleMapApiInternals={yesIWantToUseGoogleMapApiInternals}
        onClick={onMapClickCallback}
        options={{ scaleControl: true }}
      >
        {courses?.map((m) => (
          <Marker key={m.id || m.golfclubId} text={m.name} lat={m.lat} lng={m.lng} highlight={m.show} show={m.show} place={m} />
        ))}
      </GoogleMapReact>
    </div>
  );
};

// GMap.whyDidYouRender = true;
// export default React.memo(
//   GMap,
//   (prevProps, nextProps) =>
//     prevProps.zoom === nextProps.zoom && prevProps.center === nextProps.center
//   // && prevProps.courses?.length === nextProps.courses?.length
// );
export default GMap;
