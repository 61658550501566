import React, { memo } from 'react';
import styled from 'styled-components';
import InfoWindow from './InfoWindow';
import locationPin from '../../assets/img/location-pin.png';
import locationPinHighlight from '../../assets/img/location-pin-highlight.png';

const Img = styled.img`
  width: 30px;
`;

const Marker = ({ show, highlight, place, onClick }) => {
  return (
    <>
      <Img src={highlight ? locationPinHighlight : locationPin} alt="Pin" onClick={onClick} />
      {show && <InfoWindow place={place} />}
    </>
  );
};

export default memo(Marker, (prevProps, nextProps) => prevProps.show === nextProps.show && prevProps.highlight === nextProps.highlight);
