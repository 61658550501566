import React from 'react';
import { Media } from 'reactstrap';
import greens3 from '../assets/img/greens3.png';

const Title = ({ title, subTitle }) => {
  return (
    <div style={{ position: 'relative' }}>
      <Media src={greens3} alt="greens" style={{ width: '100%', border: 0, minHeight: '280px', maxHeight: '400px' }} />
      <div
        style={{
          position: 'absolute',
          top: '60%',
          left: '50%',
          marginRight: '-50%',
          transform: 'translate(-50%, -60%)',
          textAlign: 'center'
        }}
      >
        {/* eslint-disable-next-line react/no-danger */}
        {/* <h1 className="medium" dangerouslySetInnerHTML={{ __html: title }} /> */}
        <h1>{title}</h1>
        {subTitle && (
          <h2 className="mt-0" style={{ fontSize: '1.5em' }}>
            {subTitle}
          </h2>
        )}
      </div>
    </div>
  );
};

export default Title;
