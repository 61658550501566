import React from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { normalizeText } from 'normalize-text';

const isLocalEnv = process.env.REACT_APP_ENVIRONMENT !== 'prod' && process.env.REACT_APP_ENVIRONMENT !== 'dev';
const isProdEnv = process.env.REACT_APP_ENVIRONMENT === 'prod';

if (isLocalEnv) {
  // eslint-disable-next-line global-require
  const whyDidYouRender = require('@welldone-software/why-did-you-render');

  whyDidYouRender(React, {
    trackAllPureComponents: true
  });
}

if (isProdEnv) {
  Sentry.init({
    dsn: 'https://316e11f178094304b1ff413effa3193f@o486889.ingest.sentry.io/5551722',
    release: process.env.REACT_APP_SENTRY_VERSION,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.1,
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
    ignoreErrors: ['Non-Error promise rejection captured with value:']
  });
}

const identify = (user) => {
  Sentry.configureScope((scope) => {
    if (user.email) {
      scope.setUser({ email: user.email, cardNr: user.cardNr });
    }
  });
};

// eslint-disable-next-line no-extend-native
String.prototype.normalizeText =
  String.prototype.normalize ||
  // eslint-disable-next-line func-names
  function () {
    return normalizeText(this);
  };

// from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
// eslint-disable-next-line func-names
(function (arr) {
  // eslint-disable-next-line func-names
  arr.forEach(function (item) {
    // eslint-disable-next-line no-prototype-builtins
    if (item.hasOwnProperty('remove')) {
      return;
    }
    Object.defineProperty(item, 'remove', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        this.parentNode.removeChild(this);
      }
    });
  });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);

export default identify;
