import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Input, Media, Badge } from 'reactstrap';
import { Translate, withLocalize } from 'react-localize-redux';
import Autosuggest from 'react-autosuggest';
// import { FixedSizeList as List } from 'react-window';
// import AutoSizer from 'react-virtualized-auto-sizer';
import cheapRuler from 'cheap-ruler';
import { Store } from '../../Store';
import GMap from './GMap';
import Button from '../Button';
import logoBallPlayer from '../../assets/img/logo-golfball.png';
import getLanguage from '../../services/helpers';
import CourseCardCompact from 'components/CourseCardCompact';

// consts
const DEFAULT_DISTANCE_SEARCH = 100;

// Return map bounds based on list of courses
const getMapBounds = (maps, courses) => {
  const bounds = new maps.LatLngBounds();

  courses.forEach((place) => {
    bounds.extend(new maps.LatLng(place.lat, place.lng));
  });
  return bounds;
};

// Re-center map when resizing the window
// const bindResizeListener = (map, maps, bounds) => {
//   maps.event.addDomListenerOnce(map, 'idle', () => {
//     maps.event.addDomListener(window, 'resize', () => {
//       map.fitBounds(bounds);
//     });
//   });
// };

const DEFAULT_NR_COURSES = window.innerWidth >= 972 && window.innerWidth < 1200 ? 6 : 12;

let selectedCountryId = null;
let allDistricts = [];
let allCourses = [];
let allCoursesParticipating = [];
let gMap = null;
let gMaps = null;
// let clickedIconId = null;
let currentCenter = null;
let currentZoom = null;
// let childClickTransition = false;
// let defaultZoom = null;

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = (value, courses = []) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue?.length;

  return inputLength === 0
    ? []
    : // courses?.filter((c) => c.golfclub.toLowerCase().slice(0, inputLength) === inputValue);
      courses?.filter((c) => c.golfclub.toLowerCase().includes(inputValue) || c.search.includes(inputValue));
};

// When suggestion is clicked, teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => {
  return suggestion.golfclub;
};

// Use your imagination to render suggestions.
const renderSuggestion = (suggestion) => (
  <div className="d-flex flex-row align-items-center">
    {suggestion.participating === 1 && <Media src={logoBallPlayer} className="mb-0 mr-1" style={{ width: '10px' }} />}
    {`${suggestion.golfclub}, ${suggestion.country} ${suggestion.date ? `- ${suggestion.date}` : ''}`}
  </div>
);

// let autoSuggest = React.createRef();

function MapList({
  component: Component,
  courses: crs,
  showAllCourses = false,
  highlightCourse = null,
  showFilter = false,
  // showGpsFilter = false,
  filterKey = 'default',
  extraFilterData,
  showList = false,
  showFreeSearch,
  showMap,
  defaultNrOfCourses,
  mapHeight,
  translate,
  showCompactListOption = false
}) {
  document.documentElement.classList.remove('nav-open');

  const { state, dispatch } = useContext(Store);

  const filter = state.coursesFilter && state.coursesFilter[filterKey];
  const selectedCountry = filter?.country;
  const selectedDistrict = filter?.district;
  const selectedCourse = filter?.course;
  const selectedExtraFilterData = filter?.extraFilterData;
  const { mapPosition } = state;

  const languageCode = getLanguage();

  const [courses, setCourses] = useState([]);
  const [districts, setDistricts] = useState(null);
  const [countries, setCountries] = useState(null);
  const [showCompactList, setShowCompactList] = useState(false);

  const [nrOfCourses, setNrOfCourses] = useState(defaultNrOfCourses || DEFAULT_NR_COURSES);
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    const savedNrOfCourses = parseInt(localStorage.getItem('nrOfCourses'), 10);
    if (savedNrOfCourses) {
      setNrOfCourses(savedNrOfCourses);
    }
  }, []);

  // const scrollRef = useRef(null);
  // const executeScroll = () => scrollRef.current.scrollIntoView({ behavior: 'smooth' });
  useEffect(() => {
    if (highlightCourse) {
      setCourses(
        courses.map((c) => ({
          ...c,
          show: (c.id || c.golfclubId) === highlightCourse.id
        }))
      );
      dispatch({
        type: 'MAP_POSITION_DATA',
        payload: {
          zoom: 6,
          center: { lat: highlightCourse.lat - 1.5, lng: highlightCourse.lng },
          activeIconId: highlightCourse.id
        }
      });
    } else {
      const activeIconId = highlightCourse?.id || mapPosition.activeIconId;
      dispatch({
        type: 'MAP_POSITION_DATA',
        payload: {
          zoom: mapPosition.zoom,
          center: mapPosition.center,
          activeIconId
        }
      });
    }
  }, [highlightCourse]);

  useEffect(() => {
    allCourses = showAllCourses ? state.allCourses?.data : crs;
    allCoursesParticipating = crs;
    // clickedIconId = highlightCourse?.id;

    gMaps = null;

    if (showFilter) {
      const cnt = [];
      const dst = [];
      const dMap = new Map();
      const cMap = new Map();

      Object.values(crs).forEach((item) => {
        if (!dMap.has(item.dId)) {
          dMap.set(item.dId, true);
          dst.push({
            id: item.dId,
            name: item.district,
            cId: item.cId
          });
        }
        if (!cMap.has(item.cId)) {
          cMap.set(item.cId, true);
          cnt.push({
            id: item.cId,
            name: item.country
          });
        }
      });

      dst.sort((a, b) => a.name.localeCompare(b.name));
      allDistricts = dst;
      setDistricts(dst);
      setCountries(cnt);
    }

    if (selectedCountry || selectedDistrict || selectedCourse || selectedExtraFilterData) {
      showFilteredCourses();
    } else {
      setCourses(crs);
    }

    // try {
    //   executeScroll();
    // } catch {}

    return () => {
      // Do this otherwise golf course ((GolfCourse) crasches if you come direct from golf courses (GolfCourses)
      allCourses = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.allCourses?.data, crs]);

  useEffect(() => {
    showFilteredCourses();
  }, [state.coursesFilter]);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value: val }) => {
    setSuggestions(getSuggestions(val, allCourses));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onSuggestionSelected = (event, { suggestion }) => {
    if (suggestion && suggestion.lat) {
      dispatch({
        type: 'COURSES_FILTER',
        payload: {
          [filterKey]: { country: null, district: null, course: suggestion, extraFilterData: null }
        }
      });
    }
  };

  const onChangeCountry = (e) => {
    let { value: countryId } = e.target;
    let filteredDistricts;
    if (countryId) {
      countryId = parseInt(countryId, 10);
      selectedCountryId = countryId;
      filteredDistricts = allDistricts.filter((d) => d.cId === countryId);
    } else {
      filteredDistricts = null;
    }

    const selCountry = countries.find((c) => c.id === countryId);
    dispatch({
      type: 'COURSES_FILTER',
      payload: {
        [filterKey]: {
          country: selCountry,
          district: null,
          course: null,
          extraFilterData: selectedExtraFilterData
        }
      }
    });

    setDistricts(filteredDistricts);
    setValue('');
  };

  const onChangeDistrict = (e) => {
    let { value: districtId } = e.target;
    if (districtId) {
      districtId = parseInt(districtId, 10);
    }

    const selDistrict = districts.find((c) => c.id === districtId);
    dispatch({
      type: 'COURSES_FILTER',
      payload: {
        [filterKey]: {
          country: selectedCountry,
          district: selDistrict,
          course: null,
          extraFilterData: selectedExtraFilterData
        }
      }
    });

    setValue('');
  };

  const onChangeExtraFilterData = (e) => {
    const { value: val } = e.target;

    dispatch({
      type: 'COURSES_FILTER',
      payload: {
        [filterKey]: {
          country: selectedCountry,
          district: selectedDistrict,
          extraFilterData: extraFilterData.filter((d) => d.value === val)[0]
        }
      }
    });
  };

  const onMapLoaded = ({ map, maps }) => {
    // Do this behfore gMap/gMaps are defined below. Otherwise onMapChange can/will
    // throw not a LatLng or LatLngLiteral: not an Object
    allCourses = allCourses.map((m) => ({ ...m, latLng: new maps.LatLng(m.lat, m.lng) }));
    gMap = map;
    gMaps = maps;

    // setMapCenter(defaultMapPosition.center);
    // setMapZoom(map.zoom - 2);
    updateCoursesOnMap(state.mapPosition.center);
  };

  const onMapChange = ({ center, zoom }) => {
    if (selectedCountry || selectedDistrict) return;

    // Make sure map is loaded
    if (gMaps) {
      // Recalculate visible courses only if zoom changed or position changed more then a bit
      if (
        currentZoom !== zoom ||
        currentCenter.lat !== center.lat ||
        currentCenter.lng !== center.lng
        // || Math.abs(currentCenter.lat - center.lat) > 0.5
      ) {
        currentCenter = center;
        currentZoom = zoom;
        updateCoursesOnMap(center);
        dispatch({
          type: 'MAP_POSITION_DATA',
          payload: { center, zoom, activeIconId: state.mapPosition.activeIconId }
        });
      }
    }
  };

  const updateCoursesOnMap = (center = null) => {
    // console.log('updateCoursesOnMap', center);
    let latitude;
    let longitude;
    if (center) {
      latitude = center.lat;
      latitude = center.lng;
    } else if (highlightCourse) {
      latitude = highlightCourse.lat;
      latitude = highlightCourse.lng;
    }

    if (latitude) {
      const ruler = cheapRuler(latitude, 'kilometers');

      const bounds = gMap.getBounds();
      const sortedCourses = allCourses
        .filter((c) => bounds.contains(c.latLng))
        .map((c) => ({
          ...c,
          show: (c.id || c.golfclubId) === state.mapPosition.activeIconId,
          distance: Math.round(ruler.distance([c.lng, c.lat], [latitude, longitude]))
        }))
        .sort((a, b) => a.distance - b.distance);
      // console.log('Updated courses', clickedIconId, sortedCourses);
      setCourses(sortedCourses);
    } else {
      // gMap.fitBounds(bounds)
      // setCourses(allCourses);
      showFilteredCourses();
    }
  };

  const onChildClick = (key, item) => {
    const clickedIconId = item.place.id || item.place.golfclubId;
    setCourses(
      courses.map((c) => ({
        ...c,
        show: (c.id || c.golfclubId) === clickedIconId
      }))
    );
    dispatch({
      type: 'MAP_POSITION_DATA',
      payload: { activeIconId: clickedIconId }
    });
  };

  const onMapClick = () => {
    if (state.mapPosition.activeIconId) {
      setCourses(courses.map((c) => ({ ...c, show: false })));

      dispatch({
        type: 'MAP_POSITION_DATA',
        payload: { activeIconId: null }
      });
    }
  };

  const loadMoreCourses = () => {
    localStorage.setItem('nrOfCourses', nrOfCourses + DEFAULT_NR_COURSES);
    setNrOfCourses(nrOfCourses + DEFAULT_NR_COURSES);
  };

  const showFilteredCourses = () => {
    let filteredCourses = allCoursesParticipating;
    if (selectedCourse) {
      const ruler = cheapRuler(selectedCourse.lat, 'kilometers');

      filteredCourses = filteredCourses
        .map((c) => ({
          ...c,
          km: Math.round(ruler.distance([c.lng, c.lat], [selectedCourse.lng, selectedCourse.lat]))
        }))
        .filter((c) => c.km < DEFAULT_DISTANCE_SEARCH)
        .sort((a, b) => a.km - b.km);

      // setCourses(foundCourses);
    } else if (selectedDistrict) {
      if (selectedCountry?.id) {
        selectedCountryId = selectedCountry.id;
        setDistricts(allDistricts.filter((d) => d.cId === selectedCountry.id));
        filteredCourses = filteredCourses.filter((c) => c.dId === selectedDistrict.id);
      }
    } else if (selectedCountry) {
      selectedCountryId = selectedCountry.id;
      setDistricts(allDistricts.filter((d) => d.cId === selectedCountryId));
      filteredCourses = filteredCourses.filter((c) => c.cId === selectedCountryId);
    }

    if (selectedExtraFilterData) {
      filteredCourses = filteredCourses.filter((c) => c.type === selectedExtraFilterData.value);
    }

    // console.log('filteredCourses', filteredCourses);
    setCourses(filteredCourses);

    if (gMaps) {
      const bounds = getMapBounds(gMaps, filteredCourses);
      gMap.fitBounds(bounds);
    }
  };

  const clearFilter = () => {
    dispatch({ type: 'COURSES_FILTER', payload: { [filterKey]: {} } });
    setDistricts(null);
    setValue('');
    setCourses(allCoursesParticipating);
  };

  // console.log('suggestions', suggestions);

  return (
    <>
      <Row>
        {showFilter && (
          <>
            <Col sm="4">
              <Input type="select" className="input-rounded mb-2" value={selectedCountry?.id || ''} onChange={onChangeCountry}>
                <option value="">{translate('default.countries')}</option>
                {countries?.map((c) => (
                  <option key={c.id} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col sm="4">
              <Input type="select" className="input-rounded mb-2" value={selectedDistrict?.id || ''} onChange={onChangeDistrict}>
                <option value="">{translate('default.district')}</option>
                {selectedCountryId &&
                  districts &&
                  districts.map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </Input>
            </Col>
          </>
        )}
        {extraFilterData && (
          <Col sm="3">
            <Input
              type="select"
              className="input-rounded mb-2"
              value={selectedExtraFilterData?.value || ''}
              onChange={onChangeExtraFilterData}
            >
              {extraFilterData.map((f) => (
                <option key={f.value} value={f.value}>
                  {f.name}
                </option>
              ))}
            </Input>
          </Col>
        )}
        {showFreeSearch && (
          <Col sm="4">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              onSuggestionSelected={onSuggestionSelected}
              shouldRenderSuggestions={(val) => val.trim()?.length > 1}
              inputProps={{
                placeholder: translate('default.free-search'),
                value: value || '',
                onChange
              }}
            />
          </Col>
        )}
      </Row>
      {/* {showGpsFilter && (
          <Button color="success mt-2" onClick={{showCloseByCourses}}>
            <i className="fa fa-map-marker" /> Visa tävlingar i min närhet
          </Button>
        )} */}
      {/* </div> */}
      {showFilter && (selectedCountry || selectedDistrict || selectedCourse || selectedExtraFilterData) && (
        <div className="mt-3">
          {selectedCountry && <Badge color="info mr-1">{selectedCountry.name}</Badge>}
          {selectedDistrict && <Badge color="info mr-1">{selectedDistrict.name}</Badge>}
          {selectedCourse && <Badge color="info mr-1">{selectedCourse.golfclub}</Badge>}
          {selectedExtraFilterData && <Badge color="info mr-1">Tävling {selectedExtraFilterData.name}</Badge>}
          <Button small onClick={clearFilter} text={translate('default.clear-filter')} />
        </div>
      )}
      {showMap && (
        <div className="pr-sm-0 mt-3">
          <GMap
            courses={courses}
            // selectedMapCourse={clickedIconId}
            center={mapPosition.center}
            zoom={mapPosition.zoom}
            yesIWantToUseGoogleMapApiInternals
            onChildClickCallback={onChildClick}
            onMapClickCallback={onMapClick}
            onChange={onMapChange}
            onGoogleApiLoaded={onMapLoaded}
            height={mapHeight}
          />
        </div>
      )}
      {showList && (
        // <Row style={{ overflow: 'scroll', height: 1100 }}>
        <Row>
          <Col>
            <div className="products" style={{ marginTop: '20px' }}>
              {showAllCourses && selectedCourse && selectedCourse.participating !== 1 && (
                <h6 className="title">
                  <Translate id="courses.not-participating" data={{ course: selectedCourse?.golfclub }} />
                </h6>
              )}
              {showCompactListOption && (
                <Button
                  text={translate(showCompactList ? 'courses.full-list' : 'courses.compact-list')}
                  small
                  arrow={false}
                  onClick={() => setShowCompactList(!showCompactList)}
                />
              )}
              <Row>
                {showCompactList &&
                  courses?.slice(0, nrOfCourses).map((c) => (
                    <Col xs={12} key={c.id || c.golfclubId}>
                      <CourseCardCompact course={c} language={languageCode} highlight={selectedCourse?.id === c.id} />
                    </Col>
                  ))}

                {!showCompactList &&
                  courses?.slice(0, nrOfCourses).map((c) => (
                    <Col xs={12} lg={4} key={c.id || c.golfclubId}>
                      <Component course={c} language={languageCode} highlight={selectedCourse?.id === c.id} />
                    </Col>
                  ))}
              </Row>
              <Row>
                {courses?.length > nrOfCourses && (
                  <Col className="d-flex justify-content-center">
                    <Button
                      color="default"
                      data-rotation-color="gray"
                      id="successBtn"
                      outline
                      onClick={loadMoreCourses}
                      arrowDirection="down"
                      text={translate('default.show-more')}
                    />
                  </Col>
                )}
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
}

// MapList.whyDidYouRender = true;

function propsAreEqual(prevProps, nextProps) {
  // console.log('moviePropsAreEqual', prev, next);
  return prevProps.courses.length === nextProps.courses.length && prevProps.highlightCourse?.id === nextProps.highlightCourse?.id;
}

export default React.memo(withLocalize(MapList), propsAreEqual);
